// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brasilia-js": () => import("/opt/build/repo/src/pages/brasilia.js" /* webpackChunkName: "component---src-pages-brasilia-js" */),
  "component---src-pages-cardapio-brasilia-js": () => import("/opt/build/repo/src/pages/cardapio-brasilia.js" /* webpackChunkName: "component---src-pages-cardapio-brasilia-js" */),
  "component---src-pages-cardapio-js": () => import("/opt/build/repo/src/pages/cardapio.js" /* webpackChunkName: "component---src-pages-cardapio-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-2-js": () => import("/opt/build/repo/src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-passo-1-js": () => import("/opt/build/repo/src/pages/passo1.js" /* webpackChunkName: "component---src-pages-passo-1-js" */),
  "component---src-pages-passo-2-js": () => import("/opt/build/repo/src/pages/passo2.js" /* webpackChunkName: "component---src-pages-passo-2-js" */),
  "component---src-pages-passo-3-js": () => import("/opt/build/repo/src/pages/passo3.js" /* webpackChunkName: "component---src-pages-passo-3-js" */),
  "component---src-pages-passo-4-js": () => import("/opt/build/repo/src/pages/passo4.js" /* webpackChunkName: "component---src-pages-passo-4-js" */),
  "component---src-pages-passo-5-js": () => import("/opt/build/repo/src/pages/passo5.js" /* webpackChunkName: "component---src-pages-passo-5-js" */)
}

